import React, { Fragment } from 'react';
import { renderBlockWithId } from 'helpers/block-with-id';
import { BlockType } from 'types';

import {
  OpenfitCollectionPageBySlugQuery,
  SanityCollectionSliderBlockFragment,
  SanityCollectionGridBlockFragment,
  SanityEmailSignupBlockFragment,
  SanityHeroSlider,
  SanitySellPageSpacer,
  SanitySimpleMediaWithTextBlockFragment,
  SanityProductSliderFragment,
  SanitySquareImageSliderFragment,
  SanityImageBlockFragment,
  SanityHeroSmallImageFragment,
  SanityRichTextWithBackgroundFragment,
  SanitySellPageInfoCardsBlockFragment,
  SanitySubmenuFragment,
} from 'graphql-types';

import { SanityEmailSignUp } from 'components/EmailSignUp/SanityEmailSignUp';
import { SimpleMediaWithText } from 'components/SimpleMediaWithText';
import { Spacer } from 'components/Spacer/Spacer';
import { PDPPageContainer } from 'components/Pdp';
import { SubmenuSanity } from 'components/Common/Submenu/Submenu.sanity';
import { SanityHeroCarousel } from 'components/HeroCarousel/SanityHeroCarousel';
import { SanityInfoCards } from 'components/Common/InfoCards/SanityInfoCards';
import { SanityCollectionSlider } from 'components/CollectionSlider/SanityCollectionSlider';
import { CollectionGridSanity } from 'components/CollectionGrid/CollectionGrid.sanity';
import { ProductSliderSanity } from 'components/Product/Slider/ProductSlider.sanity';
import { SanitySquareImageSlider } from 'components/SquareImageSlider/SanitySquareImageSlider';
import { ImageBlockSanity } from 'components/ImageBlock/ImageBlock.sanity';
import { HeroSmallImageSanity } from 'components/HeroSmallImage/HeroSmallImage.sanity';
import { HeaderAwareAnchor } from 'components/HeaderAwareAnchor/HeaderAwareAnchor';
import { RichTextWithBackground } from 'components/RichTextWithBackground/RichTextWithBackground';
import { Divider } from 'components/Divider/Divider';

type BlocksArrayType = NonNullable<
  OpenfitCollectionPageBySlugQuery['page']
>['blocks'];

export const renderCollectionPageBlock = (block: BlockType<BlocksArrayType>) =>
  renderBlockWithId(block, getBlock(block));

function getBlock(block: BlockType<BlocksArrayType>) {
  const type = block.__typename;
  const id = block._key;

  switch (type) {
    case 'SanityEmailSignupBlock':
      return (
        <SanityEmailSignUp
          key={id}
          fields={block as SanityEmailSignupBlockFragment}
        />
      );
    case 'SanityRichTextWithBackground':
      return (
        <Fragment key={id}>
          <HeaderAwareAnchor
            id={(block as SanityRichTextWithBackgroundFragment).name?.toLowerCase()}
          />
          <RichTextWithBackground
            data={block as SanityRichTextWithBackgroundFragment}
          />
        </Fragment>
      );
    case 'SanitySimpleMediaWithTextBlock':
      return (
        <PDPPageContainer>
          <SimpleMediaWithText
            fields={block as SanitySimpleMediaWithTextBlockFragment}
          />
        </PDPPageContainer>
      );
    case 'SanitySellPageSpacer':
      return (
        <Spacer
          key={id}
          backgroundColor={
            (block as SanitySellPageSpacer)?.backgroundColor?.hex
          }
          margin={(block as SanitySellPageSpacer)?.margin}
          marginMobile={(block as SanitySellPageSpacer)?.marginMobile}
        />
      );
    case 'SanitySubmenu':
      return <SubmenuSanity key={id} fields={block as SanitySubmenuFragment} />;
    case 'SanityHeroSlider':
      return (
        <PDPPageContainer>
          <SanityHeroCarousel key={id} fields={block as SanityHeroSlider} />
        </PDPPageContainer>
      );
    case 'SanitySellPageInfoCardsBlock':
      return (
        <SanityInfoCards
          key={id}
          fields={block as SanitySellPageInfoCardsBlockFragment}
        />
      );
    case 'SanityCollectionSliderBlock':
      return (
        <SanityCollectionSlider
          key={id}
          {...(block as SanityCollectionSliderBlockFragment)}
        />
      );
    case 'SanityCollectionGridBlock':
      return (
        <CollectionGridSanity
          key={id}
          fields={block as SanityCollectionGridBlockFragment}
        />
      );
    case 'SanityProductSlider':
      return (
        <ProductSliderSanity
          key={id}
          fields={block as SanityProductSliderFragment}
        />
      );
    case 'SanitySquareImageSlider':
      return (
        <SanitySquareImageSlider
          key={id}
          fields={block as SanitySquareImageSliderFragment}
        />
      );
    case 'SanityImageBlock':
      return (
        <ImageBlockSanity key={id} fields={block as SanityImageBlockFragment} />
      );
    case 'SanityHeroSmallImage':
      return (
        <HeroSmallImageSanity
          key={id}
          fields={block as SanityHeroSmallImageFragment}
        />
      );
    case 'SanityDivider':
      return (
        <PDPPageContainer>
          <Divider key={id} />
        </PDPPageContainer>
      );
    default:
      return `Unrecognized block type: ${type}`;
  }
}
